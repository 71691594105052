<template>
  <div class="app-container">
    <Title :data="data"></Title>
    <Batch
      title="授课计划"
      :list="form.teaching_plan"
      :isBtn="true"
      style="margin-bottom: 20px"
      @refresh="getDetail()"
    />
    <Batch
      title="课程标准"
      text="课程标准"
      :list="form.course_standard"
      :isBtn="true"
      style="margin-bottom: 20px"
      @refresh="getDetail()"
    />
    <Batch
      title="课程大纲"
      text="课程大纲"
      :list="form.course_outline"
      :isBtn="true"
      style="margin-bottom: 20px"
      @refresh="getDetail()"
    />
    <Batch
      title="教学日历"
      text="教学日历"
      :list="form.teaching_calendar"
      :isBtn="true"
      style="margin-bottom: 20px"
      @refresh="getDetail()"
    />
    <Batch
      title="说课"
      text="说课"
      :list="form.lecture"
      :isBtn="true"
      style="margin-bottom: 20px"
      @refresh="getDetail()"
    />
    <div style="display: flex; justify-content: center">
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      title="批量操作"
      :visible.sync="batchAuthDialogVisible"
      width="60%"
      @close="handleClose"
    >
      <el-form ref="form" label-width="100px">
        <el-form-item label="原因">
          <el-input
            v-model="rejectReason"
            :placeholder="`请输入原因`"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :loading="batchAuthBtnLoading"
            @click="handleBatchAuth(1)"
            >通过</el-button
          >
          <el-button
            type="warning"
            :loading="batchAuthBtnLoading"
            @click="handleBatchAuth(2)"
            >驳回</el-button
          >
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Batch from "@/components/Batch/index.vue";
import request from "@/utils/request";
export default {
  components: {
    Batch,
  },
  data() {
    return {
      form: {},
      listQuery: {
        page: 1,
        limit: 10,
        type: null,
        class_course_id: null,
      },
      selected: false,
      batchAuthDialogVisible: false,
      batchAuthBtnLoading: false,
      rejectReason: null,
      data: {},
    };
  },
  created() {
    this.listQuery.type = this.$route.query.type;
    this.listQuery.class_course_id = this.$route.query.class_course_id;
    this.data = JSON.parse(this.$route.query.data);
    localStorage.setItem("classdata", JSON.stringify(this.data));
    
    this.form = {
      teaching_plan: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "teaching_plan",
        data: [],
      },
      course_standard: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "course_standard",
        data: [],
      },
      course_outline: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "course_outline",
        data: [],
      },
      teaching_calendar: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "teaching_calendar",
        data: [],
      },
      lecture: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "lecture",
        data: [],
      },
    };
    this.getDetail();
  },
  methods: {
    getDetail() {
      request({
        url: "/api/schoolend/eduAdmin/dataDetail",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        if (response.data) {
          this.form = response.data;
          this.$forceUpdate(); //强制更新
        }
      });
    },
    handlerTap(v) {
      this.selected = !this.selected;
    },
    handleBatchAuth() {},
    handleClose() {
      this.batchAuthDialogVisible = false;
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.content {
  width: 50%;
  margin: 0 auto;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .bottom_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 100px;
  }
  .bottom {
    display: flex;
  }
}
</style>
